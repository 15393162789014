import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import EventFilter, { useEventFilter } from '../shared/EventFilter';
import makeStyles from '@mui/styles/makeStyles';
import { useEventResult, useEventResults, useLogoURL, useRegattaInfo } from '../shared/UseResults';
import logo from '../logo.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeTracker, { useKioskEvent } from '../shared/ChangeTracker';
import { milliToString, timeToMilli } from '../shared/TimeUtil';

const useStyles = makeStyles((/* theme*/) => ({
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
    padding: '16px',
    margin: 0,
    // backgroundImage: "url('https://northpb.com/public/sdcc/i-MhXn3dp-XL.jpg')",
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
  },
  container: {
    borderRadius: '25px',
    paddingBottom: '8px',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    // fontFamily: "'Raleway', sans-serif",
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  redbar: {
    height: '25px',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // fontFamily: "'Lovelo', sans-serif",
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '25px',
    marginBottom: '16px',
    marginTop: '10px',
  },
}));


/**
 * Displays an elapsed time string, updating every 100ms.
 *
 * This component calculates the difference between the current time and a given start or finish time,
 * adjusting for the viewer's timezone and the event's timezone offset.
 * It then formats and displays this elapsed time as a string.
 *
 * @param {number} startMilli - The start time in milliseconds since midnight in the event's timezone.
 * @param {number} finishMilli - The finish time in milliseconds since midnight in the event's timezone.
 *                               If provided, the component displays the difference between the current time and the finish time,
 *                               prefixed with a '+'. Otherwise, it displays the time elapsed since the start time.
 * @param {string} padding - A string of '0' characters used to pad the end of the elapsed time string,
 *                           ensuring a consistent number of digits for the fractional part of the seconds.
 * @param {number} viewerTZOffsetMilli - The timezone offset of the viewer in milliseconds.
 *                                    This is used to adjust the elapsed time calculation to account for the difference
 *                                    between the viewer's timezone and the event's timezone.
 * @returns {JSX.Element} A React element displaying the elapsed time string.
 *
 * @example
 * // Example usage in a parent component.
 * <ElapsedText startMilli={10800000} finishMilli={14400000} padding="00" viewerTZOffsetMilli={-28800000} />
 * // This example would display the time elapsed since 3 AM (10800000ms) in the event timezone, or + the time until 4AM (14400000ms) in the event timezone adjusted for viewer timezone
 * // With a padding of "00" and a viewer timezone offset of -8 hours
 */
const ElapsedText: React.FC<{ startMilli: number, finishMilli: number, padding: string, viewerTZOffsetMilli: number }> = ({ startMilli, finishMilli, padding, viewerTZOffsetMilli }) => {
  const [, forceUpdate] = useState(0);
  const now = new Date();
  let nowMilli = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
  nowMilli = nowMilli % (24 * 3600 * 1000);
  const delta = nowMilli - (finishMilli ? finishMilli : startMilli) + viewerTZOffsetMilli;
  const elapsed = milliToString(delta, false, 1);

  useEffect(() => {
    const timer = setInterval(() => {
      forceUpdate((prior) => prior + 1);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return <>{`${finishMilli ? '+' : ''}${elapsed}${padding}`}</>

}


/**
 * Displays an elapsed timer for a given event, or an empty fragment if the event is not in progress.
 *
 * This component displays an `ElapsedText` component that shows the time elapsed since the start of an event,
 * or the time until the finish if a finish time is available.
 * It calculates the elapsed time based on the start and finish times of the event's entries,
 * adjusts for the viewer's timezone, and formats the elapsed time accordingly.
 * The timer will only show if the event is in progress, not finished, and not official.
 *
 * @param {object} props - The component's props.
 * @param {string} props.eventNum - The unique identifier of the event.
 * @param {boolean} props.absTime - Whether to display the absolute finish time or not.
 *                                  If true, displays the difference between current time and the start time.
 *                                  If false, the finish time is also factored into the calculation.
 * @returns {JSX.Element} A React element displaying the elapsed timer, or an empty fragment if the event is not in progress.
 *
 * @example
 * // Example usage in a parent component:
 * <ElapsedTimer eventNum="123" absTime={false} />
 * // Renders an elapsed timer for event "123", showing the time since start, or the time until finish if available.
 * <ElapsedTimer eventNum="456" absTime={true} />
 * //Renders an elapsed timer for event "456", showing the time since start, regardless of finish time
 */
const ElapsedTimer: React.FC<{ eventNum: string, absTime: boolean }> = ({ eventNum, absTime }) => {
  const [event] = useEventResult(eventNum);
  const [regattaInfo] = useRegattaInfo();
  if (event === undefined) {
    return <></>;
  }
  const paddingCount = Number(regattaInfo?.ResultDigits || '3') - 1;
  const digitPadding = '0'.repeat(paddingCount);
  let { entries } = event;
  if (!entries) entries = [];
  const finished = event.Finished;
  const official = event.Official;

  const viewerTZOffsetHrs = -new Date().getTimezoneOffset() / 60;
  let viewerTZOffsetMilli = 0;

  if (typeof event.TZOffset === 'number') {
    viewerTZOffsetMilli = (event.TZOffset - viewerTZOffsetHrs) * 60 * 60 * 1000;
  }

  let start = '';
  let inProgress = false;
  let finishMilli = 0;  // earliest finish time
  for (const row of entries) {
    const startTimeString = row.S_time;
    const finishTime = row.G_Finish_time_raw;
    start = start || startTimeString;
    const adj = row.AdjTime || '';
    const skip = adj === 'DNS' || adj === 'DNF';
    if (skip) {
      continue;
    }
    const milli = timeToMilli(finishTime);
    if (finishMilli && milli) {
      finishMilli = Math.min(finishMilli, milli);
    } else if (milli) {
      finishMilli = milli;
    }
    inProgress = inProgress || Boolean(!finishTime);
    // finish = finish || adj;
  }
  inProgress = inProgress && Boolean(start) && !official && !finished;
  const startMilli = timeToMilli(start);
  if (inProgress) {
    return <ElapsedText startMilli={startMilli} finishMilli={absTime ? 0 : finishMilli} padding={digitPadding} viewerTZOffsetMilli={viewerTZOffsetMilli} />
  }

  return <></>

}


const EventLogo: React.FC = () => {
  const [logoURL] = useLogoURL();
  return (
    <Box
      component="img"
      src={logoURL ? logoURL : logo}
      sx={{ width: 'auto', height: 68, marginLeft: '8px', cursor: 'none' }}
    />
  );
};

/**
 * The main component for displaying live stream event information.
 *
 * This component renders a live view of a selected event, including the event title,
 * subtitle, and a list of entries with their current status. It supports various
 * customization options through URL parameters, allowing control over the display
 * of different elements, colors, and fonts. The component also allows for navigation
 * between events and filtering of event data.
 *
 * @returns {JSX.Element} A React element representing the live stream event display.
 *
 * @example
 * // Example usage in a React application:
 * <LiveStreamEvent />
 * // This will render the live stream view with default settings, displaying the first event.
 *
 * // Example URL parameters for customization:
 * // ?eventNum=10&waypoint=finish&width=800px&page-bg=black&bar-bg=red&title-color=white&bg=blue&showElapsed=true&absTime=false&showTitle=true&showBody=true&showLogo=true&showStroke=true&showCrew=true&fontSize=16px&title=MyRegatta&subtitle=Event10&align=top&kiosk=true
 * // eventNum: The event number to display.
 * // waypoint: The waypoint to display (e.g., 'finish', 'start').
 * // width: The width of the display container.
 * // page-bg: The background color of the page.
 * // bar-bg: The background color of the title bar.
 * // title-color: The color of the title text.
 * // bg: The background color of the container.
 * // showElapsed: Whether to show the elapsed timer.
 * // absTime: Whether to show absolute times.
 * // showTitle: Whether to show the title.
 * // showBody: Whether to show the list of entries.
 * // showLogo: Whether to show the event logo.
 * // showStroke: Whether to show the stroke information.
 * // showCrew: Whether to show the crew information
 * // fontSize: The font size for the text.
 * // title: The custom title to display.
 * // subtitle: The custom subtitle to display.
 * // align: align the result section with the 'top','center', or 'bottom' of the view.
 * // kiosk: enables switching of event via kiosk mode
 */
const LiveStreamEvent: React.FC = () => {
  const classes = useStyles();
  const [regattaData] = useEventResults();
  const [eventFilter, setEventFilter] = useEventFilter();
  const [regattaInfo] = useRegattaInfo();
  const [showEventFilter, setShowEventFilter] = useState(false);
  const location = useLocation();
  const [kioskEvent] = useKioskEvent();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);

  const getSearchParam = (key: string, defaultValue: string) => {
    const val = searchParams.get(key);
    return val ? val : defaultValue;
  };

  const getColorParam = (key: string, defaultValue: string) => {
    const val = searchParams.get(key);
    if (!val) {
      return defaultValue;
    }
    if (val.match(/^[0-9a-fA-F]{6}([0-9a-fA-F]{2})?$/)) {
      return `#${val}`; // hex rgb
    }
    return val;
  };

  const selectedEvent = regattaData.find((event) => event.EventNum === eventFilter) || regattaData[0];
  const waypoint = getSearchParam('waypoint', 'finish');
  const width = searchParams.get('width') || '600px';
  const showScheduleOrder = waypoint.toLowerCase() === 'start';
  const align = getSearchParam('align', 'bottom').toLowerCase();

  const pageBg = searchParams.get('page-bg') || 'none';
  const barBg = getColorParam('bar-bg', 'rgba(167,28,32,100)');
  const titleTextColor = getColorParam('title-color', 'white');
  const bg = getColorParam(
    'bg',
    pageBg === 'none' || pageBg === 'transparent' ? 'rgba(27,49,93,0.80)' : 'rgba(27,49,93,1.0)'
  );

  const showElapsed = getSearchParam('showElapsed', 'true') === 'true';
  const kioskEnabled = getSearchParam('kiosk', 'false') === 'true';
  // showTime is deprecated in favor of absTime but use it if specified
  const absTime = getSearchParam('absTime', getSearchParam('showTime', 'false')) === 'true';
  const showTitle = getSearchParam('showTitle', 'true') === 'true';
  const showBody = getSearchParam('showBody', 'true') === 'true';
  const showLogo = getSearchParam('showLogo', 'true') === 'true';
  const showStroke = getSearchParam('showStroke', 'false') === 'true';
  const showCrew = getSearchParam('showCrew', 'true') === 'true';
  const fontSize = getSearchParam('fontSize', '14px');

  const titleText = getSearchParam('title', regattaInfo?.Title || '');
  const subtitleText = getSearchParam('subtitle', selectedEvent?.EventInfo || '');
  const columnTitles = regattaInfo?.Titles || {};
  const alignStyle = { justifyContent: 'center', alignItems: 'flex-end' };

  switch (align) {
    case 'top':
      alignStyle.alignItems = 'flex-start';
      break;
    case 'bottom':
      alignStyle.alignItems = 'flex-end';
      break;
    case 'center':
      alignStyle.alignItems = 'center';
    default:
      break;
  }

  const getTitle = (name: string) => {
    if (name === 'Stroke/Cox') {
      return columnTitles.Stroke || `${t('Stroke')}/${t('Cox')}`;
    }
    return columnTitles[name] || t(name);
  };

  useEffect(() => {
    const urlEventNum = searchParams.get('eventNum');
    if (urlEventNum) {
      setEventFilter(urlEventNum);
    } else if (!eventFilter && regattaData.length) {
      setEventFilter(regattaData[0].EventNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regattaData?.[0]?.EventNum]);

  useEffect(() => {
    if (kioskEnabled && kioskEvent) {
      setEventFilter(kioskEvent);
    }
  }, [kioskEvent, kioskEnabled, setEventFilter]);

  let entries = selectedEvent?.entries || [];

  // Make a copy so we don't disturb the backing store
  entries = entries.map((entry) => ({ ...entry }));
  if (showScheduleOrder) {
    // default is finish order so resort
    entries = entries.sort((a, b) => a.Index - b.Index);
  } else {
    if (waypoint.toLowerCase() !== 'finish') {
      // calculate values for Adjtime and Delta for display based on the waypoint selected
      const key = `G_${waypoint}_time`;
      entries.forEach((entry) => {
        entry.milli = entry[key] ? timeToMilli(entry[key]) : 1e9;
        entry.AdjTime = entry[key];
      });

      entries.sort((a, b) => a.milli - b.milli);
      const first = entries[0]?.milli || 0;
      entries.forEach((entry) => {
        entry.Delta = entry.milli === 1e9 ? '' : milliToString(entry.milli - first, false, 1);
      });
    }
  }
  // console.log(JSON.stringify(entries, null, 2));

  const onNextEvent = () => {
    if (regattaData.length === 0) {
      return;
    }
    const index = regattaData.findIndex((event) => event.EventNum === eventFilter);
    const nextIndex = Math.min(regattaData.length - 1, index + 1);
    setEventFilter(regattaData[nextIndex].EventNum);
  };
  const onPrevEvent = () => {
    if (regattaData.length === 0) {
      return;
    }
    const index = regattaData.findIndex((event) => event.EventNum === eventFilter);
    const nextIndex = Math.max(0, index - 1);
    setEventFilter(regattaData[nextIndex].EventNum);
  };

  if (!selectedEvent?.Event) {
    return <></>;
  }

  return (
    <>
      {showEventFilter && (
        <Box sx={{ position: 'absolute', width: '100%' }}>
          <EventFilter />
        </Box>
      )}
      <Box className={classes.body} sx={{ background: pageBg, ...alignStyle }} style={{ ...alignStyle }}>
        <ChangeTracker />
        <Stack direction="column" className={classes.container} sx={{ width: `${width}`, background: bg }}>
          {showTitle ? (
            <Stack direction="row" sx={{ paddingTop: '16px' }} onClick={() => setShowEventFilter(!showEventFilter)}>
              {showLogo && <EventLogo />}
              <Stack direction="column" sx={{ paddingLeft: '26px' }}>
                <Box className={classes.title} sx={{ color: titleTextColor }}>
                  {titleText}
                </Box>
                <Box className={classes.subtitle} sx={{ color: titleTextColor }}>
                  {subtitleText}
                </Box>
                <Box className={classes.subtitle} sx={{ color: titleTextColor }}>
                  { }
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Box sx={{ height: '16px' }} onClick={() => setShowEventFilter(!showEventFilter)} />
          )}
          <Box className={classes.redbar} sx={{ background: barBg }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                paddingLeft: '16px',
                fontSize,
                whiteSpace: 'nowrap' /* Prevents the text from wrapping to a new line */,
                overflow: 'hidden' /* Ensures the overflow is hidden */,
                textOverflow: 'ellipsis',
              }}
              onClick={onPrevEvent}
            >
              {`${showScheduleOrder && showBody ? 'START LIST - ' : ''}${selectedEvent?.Event}`}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{
                paddingRight: '16px',
                fontSize,
                whiteSpace: 'pre',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                minWidth: '50px',
                height: '25px', // required so blank content is clickable
              }}
              onClick={onNextEvent}
            >
              {showBody ? showScheduleOrder ? '' : getTitle(absTime ? 'Time' : 'Split') : showElapsed ? <ElapsedTimer absTime={absTime} eventNum={eventFilter} /> : ''}
            </Typography>
          </Box>
          {showBody &&
            entries.map((entry, rowNum) => {
              let time = <></>;
              if (!showScheduleOrder) {
                if (showElapsed && entry.AdjTime === 'In Progress') {
                  time = <ElapsedTimer eventNum={eventFilter} absTime={absTime} />
                } else {
                  if (absTime || rowNum === 0) {
                    // always show the first row as absoluste time since it represents 1st place
                    time = <>{entry.AdjTime}</>;
                  } else {
                    time = <>{entry.Delta ? `+${entry.Delta.replace(/^[:0]+/, '')}` : entry.AdjTime}</>;
                  }
                }
              }
              return (
                <Box
                  key={entry.Bow}
                  sx={{
                    height: '25px',
                    background: 'rgba(255,255,255,0.50)',
                    marginBottom: '5px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize,
                    lineHeight: '25px',
                    cursor: 'none',
                  }}
                >
                  <Grid container>
                    {/* First column - 30px wide */}
                    <Grid item>
                      <Box
                        width={30}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                      >
                        {entry.Bow}
                      </Box>
                    </Grid>

                    {/* Center column - takes up remaining space */}
                    <Grid
                      item
                      xs
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap' /* Prevents the text from wrapping to a new line */,
                        overflow: 'hidden' /* Ensures the overflow is hidden */,
                      }}
                    >
                      <Box
                        sx={{
                          paddingLeft: '8px',
                        }}
                      >
                        {showCrew && entry.Crew}
                        {showStroke && entry.Stroke && ` (${entry.Stroke})`}
                      </Box>
                    </Grid>

                    {/* Last column - 100px wide */}
                    <Grid item>
                      <Box
                        width={110}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          fontFamily: 'monospace',
                          paddingRight: '8px',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap' /* Prevents the text from wrapping to a new line */,
                          overflow: 'hidden' /* Ensures the overflow is hidden */,
                        }}
                      >
                        {time}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Stack>
      </Box>
    </>
  );
};

export default LiveStreamEvent;
